global.jQuery = global.$ = window.jQuery = require('jquery');
var skrollr = require('skrollr');
var jsSHA = require("jsSHA");
var ScrollMagic = require('ScrollMagic');
require('cookie');
require('velocity');
import 'core-js/features/number/is-nan'
import 'custom-event-polyfill'
import {
	Navigation,
	Autoplay,
	Pagination,
	EffectFade,
	Swiper,
	Thumbs
} from 'swiper'
Swiper.use([Pagination, Autoplay, Navigation, EffectFade, Thumbs])
import {
	gsap
} from "gsap";
import {
	ScrollTrigger
} from "gsap/ScrollTrigger";
import {
	ScrollToPlugin
} from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);


$(function () {

	// Burger Menu Viewport
	const setFillHeight = () => {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}

	window.addEventListener('resize', setFillHeight);

	setFillHeight();
	
	var swiper = new Swiper('.c-slide', {
		slidesPerView: 1,
		loop: true,
		speed: 800,
		effect: 'fade',
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: '.c-slide__next',
			prevEl: '.c-slide__prev',
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true
		},
		fadeEffect: {
			crossFade: true
		}
	});
	
	
	// Page Top

	const pagetop = $('.p-footer__pagetop');
	pagetop.on('click', function () {
		gsap.to(window, {
			duration: 1,
			scrollTo: 0
		});
	});

	// Global Navigation

	const burger = $('.c-burger');
	const gnav = $('.l-navGlobal');
	const header = $('.l-header');
	let burgerFlag = false;
	burger.each(function () {
		burger.on('click', function () {
			if (!burgerFlag) {
				burger.addClass('isOpen');
				gnav.addClass('isActive');
				burgerFlag = true;
				header.addClass('isActive');
			} else {
				burger.removeClass('isOpen');
				gnav.removeClass('isActive');
				header.removeClass('isActive');

				burgerFlag = false;
			}
		});
	});

	checkHeaderClass();

	function checkHeaderClass() {
		if (burger.hasClass('isOpen')) {
			burger.removeClass('isOpen');
		}
		if (gnav.hasClass('isActive')) {
			gnav.removeClass('isActive');
		}
		if (header.hasClass('isActive')) {
			header.removeClass('isActive');
		}
		burgerFlag = false;
	}
	
	const loginNav = $('.l-header__item_login');
	let LoginFlag = false;
	
	loginNav.on('click',function(){
		if (!LoginFlag) {
			$(this).addClass('active');
			LoginFlag = true;
		} else {
			$(this).removeClass('active');
			LoginFlag = false;
		}
	});

	var controller = new ScrollMagic.Controller();
	var jsAddClasses = Array.prototype.slice.call(document.getElementsByClassName('js-active')),
		jsMoveSimples = Array.prototype.slice.call(document.getElementsByClassName('js-view')),
		jsMoveMultis = Array.prototype.slice.call(document.getElementsByClassName('js-multi'));

	jsAddClasses.map(function (jsAddClass) {
		return new ScrollMagic.Scene({
				triggerElement: jsAddClass,
				triggerHook: 0.8
			}).setClassToggle(jsAddClass, 'is-active')
			.reverse(false)
			.addIndicators()
			.addTo(controller);
	});

	$('.js-view , .js-move').velocity({
		opacity: 0,
		translateY: 30
	}, 40);
	
	$('.js-left').velocity({
		opacity: 0,
		translateX: 60,
		translateY: 0
	}, 40);
	
	$('.js-right').velocity({
		opacity: 0,
		translateX: (-60),
		translateY: 0
	}, 40);

	function multiMoves(_event, _this) {
		var $self = $(_this);
		$self.find('.js-move').each(function (index) {
			$(this).velocity({
				opacity: 1,
				translateY: 0,
				translateX: 0
			}, {
				delay: 250 * index,
				duration: 800
			});
		});
	}

	function simpleMove(_event, _this) {
		var $self = $(_this);
		$self.velocity({
			opacity: 1,
			translateX: 0,
			translateY: 0
		}, {
			duration: 800
		});
	}


	jsMoveSimples.map(function (jsMoveSimple) {
		return new ScrollMagic.Scene({
			triggerElement: jsMoveSimple,
			triggerHook: 0.8,
			reverse: false,
		}).on('enter', function (event) {
			var thisTarget = jsMoveSimple;
			simpleMove(event, thisTarget);
		}).addTo(controller);
	});

	jsMoveMultis.map(function (jsMoveMulti) {
		return new ScrollMagic.Scene({
			triggerElement: jsMoveMulti,
			triggerHook: 0.8,
			reverse: false,
		}).on('enter', function (event) {
			var thisTarget = jsMoveMulti;
			multiMoves(event, thisTarget);
		}).addTo(controller);
	});

	
	$('a[href^="#"]').click(function () {
		var speed = 500,
			href = $(this).attr("href"),
			target = $(href == "#" || href == "" ? 'html' : href),
			offset = target.offset().top,
			headerHeight = $('.l-header').innerHeight(),
			position = offset - headerHeight;

		$("html, body").animate({
			scrollTop: position
		}, speed, "swing");
		return false;
	});

	

});


/*---------------------------------------------
	skrollr setting
---------------------------------------------*/

var isMobile = false;

isMobile = $('html').is('.skrollr-mb');

if (!isMobile) {
	window.onload = function () {
		var s = skrollr.init({
			forceHeight: false,
			mobileDeceleration: 0.01,
			mobileCheck: function () {
				return isMobile;
			}
		});
	}
}